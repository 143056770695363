<template>
  <div id="management">
    <div class="content">
      <div class="container">
        <div class="d-flex space-between respon-menage">
          <p class="tab-setting">ข้อมูลนวัตกรรม</p>
          <div>
            <el-button round class="btn-custom-add">
              <router-link to="/manageinnovation/innovation">
                <i class="el-icon-plus"></i>เพิ่มนวัตกรรม
              </router-link>
            </el-button>
          </div>
        </div>
        <el-table :data="dataIP" style="width: 100%" class="custom-table">
          <el-table-column
            type="index"
            label="no"
            width="50"
            align="center"
            :index="(index) => indexMethod(index, page, pageSize)"
          >
          </el-table-column>
          <el-table-column prop="projectName" label="ชื่อนวัตกรรม">
          </el-table-column>
          <el-table-column align="right" :width="widthMax < 575.98 ? '90' : '200'">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                class="add-openday"
                @click="
                  $router.push(`/manageinnovation/innovation?id=${scope.row._id}`)
                "
                >แก้ไข</el-button
              >
              <el-button
                size="mini"
                type="primary"
                class="del-openday"
                @click="modalDel(scope.row._id)"
                >ลบ</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="pd-y-3 text-center">
          <el-pagination
            @current-change="changePage"
            background
            layout="prev, pager, next"
            :total="total"
            :page-size="pageSize"
            :current-page="page"
          ></el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      title="Delete"
      :visible.sync="centerDialogVisible"
      width="29%"
      center
      class="custom-modal"
    >
      <span>คุณต้องการลบข้อมูลนวัตกรรมนี้ใช่หรือไม่ ?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">ยกเลิก</el-button>
        <el-button
          size="mini"
          type="primary"
          class="del-openday"
          @click="delInnovation()"
          >ลบ</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { HTTP } from "@/service/axios.js";
export default {
  mounted() {
    this.fetchInnovation();
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      widthMax: window.innerWidth,
      centerDialogVisible: false,
      dataIP: [],
      page: 1,
      total: 0,
      loading: true,
      dataId: "",
      pageSize: 10,
    };
  },
  methods: {
    fetchInnovation() {
      HTTP.get(
        `innovations/${"all"}/${this.pageSize}/${
          (this.page - 1) * this.pageSize
        }/${"all"}`
      )
        .then((res) => {
          if (res.data.success) {
            console.log("fetchInnovation", res.data);
            this.dataIP = res.data.obj;
            this.total = res.data.count.length;
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("fetchInnovation error", e);
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        });
    },
    changePage(page) {
      this.page = page;
      this.fetchInnovation();
    },
    modalDel(id) {
      this.centerDialogVisible = true;
      this.dataId = id;
    },
    delInnovation() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.delete(`innovation/${this.dataId}`)
        .then((res) => {
          if (res.data.success) {
            console.log(res.data);
            this.$message({
              message: "ลบข้อมูลนวัตกรรมสำเร็จ",
              type: "success",
              duration: 4000,
            });
            this.page = 1;
            this.centerDialogVisible = false;
            this.fetchInnovation();
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("Delete Innovation error", e);
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        });
    },
  },
};
</script>